import React from 'react'
import Script from 'next/script';
import { getFormattedDate, formatToGMTFromArgentinaDateTime } from 'helpers'

const LdjsonLiveBlogPosting = ({ dataNewsLive }) => {
  const endDate = new Date(dataNewsLive?.publishedDate)
  endDate.setHours(endDate.getHours() + 23)

  let imgUrl =
    dataNewsLive.relatedVideos?.thumbnail ||
    dataNewsLive.related_image_url ||
    ''
  const [width, height] = imgUrl
    .match(/\d+x\d+/)?.[0]
    ?.split('x')
    .map(Number) || [320, 180]
  const description = dataNewsLive.summary || ''
  const parseDesc = description.replace(/<[^>]*>?/g, '')

  let dateModified
  try {
    dateModified = new Date(dataNewsLive?.lastModified).toISOString()
  } catch (e) {
    dateModified = new Date().toISOString()
  }

  const contentUrl = dataNewsLive.relatedVideos
    ? dataNewsLive.relatedVideos.videoFiles.mp4
    : ''
  const liveBlogItems = dataNewsLive.body.filter(
    (item) => item.type === 'liveblog',
  )
  const liveBlogBodies = liveBlogItems.flatMap((liveBlogItem) =>
    liveBlogItem.value.body.filter((item) => item.type === 'text'),
  )
  const liveBlogUpdates = liveBlogBodies
    .map((noticia) => {
      const hasH2 = /<h2>(.*?)<\/h2>/g.test(noticia.value)
      const hasDateParagraph = !!noticia.dateParagraph

      if (!hasH2 || !hasDateParagraph) {
        return null // No hay suficiente información para procesar este elemento
      }

      const blogPostingDate =
        formatToGMTFromArgentinaDateTime(noticia.dateParagraph) || ''

      // Extraer el contenido entre <h2> y </h2> para formar articleBody
      const h2ContentMatch = noticia.value.match(/<h2>(.*?)<\/h2>/g) || []
      let articleBody = ''
      h2ContentMatch.forEach((match) => {
        const content = match.replace(/<\/?[^>]+(>|$)/g, '').trim()
        articleBody += (articleBody ? ' ' : '') + content
      })

      const author = noticia.authorParagraph
        ? {
            '@type': 'Person',
            name: noticia.authorParagraph.name,
            url: `https://www.clarin.com/autor/${noticia.authorParagraph.slug}.html`,
          }
        : {
            '@type': 'Organization',
            name: 'Clarín',
            url: 'https://www.clarin.com/',
          }

      return {
        '@type': 'BlogPosting',
        headline: dataNewsLive.title,
        datePublished: blogPostingDate,
        dateModified: blogPostingDate,
        articleBody: articleBody.trim(),
        url: dataNewsLive.url,
        author,
        publisher: {
          '@type': 'Organization',
          name: 'Clarín',
          logo: {
            '@type': 'ImageObject',
            url: 'https://www.clarin.com/img/logo_clarin.png',
            width: 234,
            height: 60,
          },
        },
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': contentUrl,
        },
        image: {
          '@type': 'ImageObject',
          url: imgUrl,
          height: height,
          width: width,
        },
      }
    })
    .filter((item) => item !== null)

  let data = {
    '@context': 'http://schema.org',
    '@type': 'LiveBlogPosting',
    '@id': `${dataNewsLive.url}#liveBlogPosting`,
    headline: dataNewsLive.title,
    description: parseDesc,
    coverageStartTime: new Date(dataNewsLive.publishedDate).toISOString(),
    coverageEndTime: endDate.toISOString(),
    about: {
      '@type': 'Event',
      name: dataNewsLive.title,
      startDate: new Date(dataNewsLive.publishedDate).toISOString(),
      endDate: endDate.toISOString(),
      description: parseDesc,
      eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
      eventStatus: 'https://schema.org/EventScheduled',
      location: {
        '@type': 'VirtualLocation',
        name: 'Clarín',
        url: dataNewsLive.url,
      },
      organizer: {
        '@type': 'Organization',
        name: 'Clarín',
        url: 'https://www.clarin.com/',
      },
      performer: {
        '@type': 'Organization',
        name: 'Clarín',
        url: 'https://www.clarin.com/',
      },
      offers: {
        '@type': 'Offer',
        availability: 'https://schema.org/InStock',
        price: '0.00',
        priceCurrency: 'ARS',
        validFrom: new Date(dataNewsLive.publishedDate).toISOString(),
        url: dataNewsLive.url,
      },
      image: {
        '@type': 'ImageObject',
        url: imgUrl,
        height: height,
        width: width,
      },
    },
    publisher: {
      '@type': 'Organization',
      '@id': `${dataNewsLive.url}#organization`,
      name: 'Clarín',
      url: 'https://www.clarin.com/',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.clarin.com/img/logo_clarin.png',
        width: 234,
        height: 60,
      },
      sameAs: [
        'https://www.facebook.com/clarincom/',
        'https://twitter.com/clarincom',
      ],
    },
    image: {
      '@context': 'http://schema.org',
      '@type': 'ImageObject',
      url: imgUrl,
      height: height,
      width: width,
    },
    author: {
      '@type': 'Organization',
      name: 'Clarín.com',
    },
    liveBlogUpdate: liveBlogUpdates,
    url: `${dataNewsLive.url}#liveBlogPosting`,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': dataNewsLive.url,
    },
  }

  return (
    <Script
      strategy="beforeInteractive"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
    
  )
}

export default LdjsonLiveBlogPosting
