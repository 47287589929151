import LdjsonNewsArticle from "@/shared/Schemas/NewsArticle";
import LdjsonOrg from "@/shared/Schemas/Organization";
import LdjsonBreadcrumbList from "@/shared/Schemas/BreadcrumbList";
import LdjsonNewsMediaOrg from "../Schemas/NewsMediaOrganization";
import LdjsonItemList from "../Schemas/ItemList";
import { MetaTagsNews } from "@/shared/MetaTags";
import LinkPreconnect from "../LinkPreconnect";
import LdjsonLiveBlogPosting from "../Schemas/LiveBlogPosting";
import LdjsonRecipes from "../Schemas/Recipes";
import LdjsonImageObject from "../Schemas/ImageObject";
import LdjsonVideoObject from "../Schemas/VideoObject";
import LdjsonOpta from "@/shared/Schemas/Opta";
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

const HeadNews = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  // si no tengo props dataHead, busco contexto
  if(Object.keys(props).length === 0 && !!formatContent) {
    props = { 
      ...props,
      dataHead: {...formatContent?.dataHead }
    }
  }
  
  const {
    authors,
    dataImageShare,
    keywords,
    lastModified,
    mam,
    metadata,
    metas,
    publishedDate,
    relatedVideos,
    relatedNews,
    sectionId = "",
    sectionUrl,
    sections,
    subtitle,
    summary,
    body,
    title,
    url,
    slug,
    shared,
    relAmp,
    accesibleForFree,
    isPreview,
    type,
    features, 
    related,
    createdBy = [],
    urlCanonical,
  } = props.dataHead;

  const content = relatedNews 
    ? relatedNews.length > 0 && relatedNews?.map(newsItem => newsItem.value ? newsItem.value : "")
    :"";

  const relatedImageUrl =
    dataImageShare?.desktop?.image != "#"
      ? dataImageShare.desktop.image
      : dataImageShare.mobile.image;

  const dataMetaTags = {
    keywords,   
    authors,
    lastModified,
    metas,
    metadata,
    publishedDate,
    relatedNews,
    relatedImageUrl,
    sectionId,
    sectionUrl,
    sections,
    subtitle,
    summary,
    body,
    title,
    url,
    shared,
    relAmp, 
    type,
    features, 
    createdBy,
    urlCanonical
  };
  
  const liveBlog = metadata?.typeWeb ? metadata.typeWeb : "";
  const recipe = type ? type : "";
  return (
    <>
      <MetaTagsNews {...dataMetaTags} />
      <LinkPreconnect/>
      <LdjsonBreadcrumbList sections={sections} />
      <LdjsonOrg />
      <LdjsonNewsMediaOrg />
      <LdjsonNewsArticle
        dataNews={{
          publishedDate,
          subtitle,
          title,
          summary,
          body,
          sections,
          authors,
          publishedDate,
          keywords: keywords,
          related_image_url: relatedImageUrl,
          url: process.env.NEXT_PUBLIC_DOMAIN_URL + url,
          relatedVideos,
          lastModified: metadata?.typeWeb != "live_blog" ? lastModified : lastModified,
          accesibleForFree,
          type: metadata?.typeWeb != "live_blog" ? "normal" : "liveblog"
        }}
      />
      {liveBlog == "live_blog" && 
      <LdjsonLiveBlogPosting
        dataNewsLive={{
          publishedDate,
          subtitle,
          title,
          summary,
          body,
          sections,
          authors,
          keywords: keywords,
          related_image_url: relatedImageUrl,
          url: process.env.NEXT_PUBLIC_DOMAIN_URL + url,
          relatedVideos,
          lastModified: lastModified,
        }}
      />
      }
      {recipe == "recipe" &&
        <LdjsonRecipes
        dataRecipes={{
          publishedDate,
          subtitle,
          title,
          related_image_url: relatedImageUrl,
          features,
          body,
          sectionUrl,
          authors,
          slug
        }}
        />
      }
      <LdjsonItemList props={ content }/>
      {(liveBlog != "live_blog" && mam?.enabled && mam?.sport !== "Tenis") && (
        <LdjsonOpta
          dataOpta={mam}
          dataNews={{
            url: process.env.NEXT_PUBLIC_DOMAIN_URL + url,
            relatedVideos,
            related_image_url: relatedImageUrl,
            summary
          }}
        />)
      }
      <LdjsonImageObject 
        dataImage={{
          related_image_url: relatedImageUrl,
          related, 
          dataImageShare
      }}
      />
      { relatedVideos &&
       <LdjsonVideoObject 
        dataVideo={
          {
            relatedVideos,
            lastModified,
            publishedDate,
            url: process.env.NEXT_PUBLIC_DOMAIN_URL + url
          }
        }
      />
      }
    </>
  );
};
export default HeadNews;
