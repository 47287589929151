import Script from 'next/script';

const LdjsonRecipes = ({ dataRecipes }) => {
    if (!dataRecipes) {
        return <></>;
    }

    const description = dataRecipes.subtitle ? dataRecipes.subtitle : "Las mejores y más ricas recetas en Clarín.com";

    const ingredientsAll = dataRecipes.body ? dataRecipes.body.filter((element) => (element.type == "ingredients") ) : "";
    const ingredientsObject = ingredientsAll ? ingredientsAll.map((element) => (element.value)) : "" ;

    const recipeInstructionsAll = dataRecipes.body ? dataRecipes.body.filter((element) => (element.type == "text") ) : "";
    const recipeInstructionsObject = recipeInstructionsAll ? recipeInstructionsAll.map((element) => (element.value)) : "" ;
    const recipeInstructions = recipeInstructionsObject.toString().replace(/<[^>]*>?|,/g, "");

    const preparationTime = dataRecipes.features?.preparationTime ? dataRecipes.features.preparationTime : "30";
    const portions = dataRecipes.features?.portions ? dataRecipes.features.portions : "4";
    const recipeCategory =  dataRecipes?.sectionUrl ? dataRecipes?.sectionUrl : "" ;

    const slug = dataRecipes.authors ? dataRecipes.authors[0].slug : "";
    const cookingTime = dataRecipes.features?.preparationTime ? dataRecipes.features.cookingTime : "30";
    const typeCousin = dataRecipes?.features?.typeOfCuisine;
    const calories = dataRecipes?.features?.calories;

    const totalTimeIsSeparated = preparationTime && cookingTime;

    function convertTimeISO(minutes) {
        if (!minutes) return null;
        const hours = Math.floor(minutes / 60);
        const mins = Math.round(minutes % 60);
        return hours > 0 ? `PT${hours}H${mins}M` : `PT${mins}M`;
    }

    function calculateTime(totalMinutes, percentage) {
        var cookTimeMinutos = totalMinutes*percentage;
        var cookTime = convertTimeISO(cookTimeMinutos);

        return cookTime;
    }

    const totalTime = totalTimeIsSeparated ? preparationTime + cookingTime :  preparationTime;
    const dataRecipe = {
        "@context": "https://schema.org",
        "@type": "Recipe",
        "cookTime": totalTimeIsSeparated ? convertTimeISO(cookingTime) : calculateTime(preparationTime, 0.3),
        "datePublished": dataRecipes.publishedDate,
        "description": description,
        "keywords": dataRecipes.keywords ? dataRecipes.keywords : "Recetas de cocina, postres, entradas, carnes, ensaladas, gourmet",
        "image": dataRecipes.related_image_url,
        "recipeIngredient": [
        ingredientsObject
        ],
        "name": dataRecipes.title,
        author:
        dataRecipes.authors ?
        dataRecipes.authors.map((author) => ({
        type: "Person",
        name: author.name,
        url: "https://www.clarin.com/autor/recetas/" + slug + ".html"
        })) :
        {
        type: "Organization",
        name: "Redacción Clarín",
        url: "https://www.clarin.com/",
        },
        "prepTime": totalTimeIsSeparated ? convertTimeISO(preparationTime) : calculateTime(preparationTime, 0.7),
        "recipeInstructions": recipeInstructions,
        "recipeYield": portions,
        "recipeCategory": recipeCategory,
        "totalTime": convertTimeISO(totalTime),
        "recipeCuisine": typeCousin,
        "nutrition": calories && {
            "@type": "NutritionInformation",
            "calories": calories
        }
    };

    return (
        <Script
            strategy="beforeInteractive"
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(dataRecipe) }}
        />
    );
};

export default LdjsonRecipes;