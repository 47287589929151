import Script from 'next/script';

const LdjsonImageObject = ({ dataImage }) => {
  const {
    related_image_url: img,
    related: {
      images: [
        {
          epigraphe = "Imagen sin descripción",
          value: {
            author: authorPhoto = "FTP Clarín",
            title: titlePhoto = "Clarín",
          } = {},
        } = {},
      ] = [],
    } = {},
  } = dataImage || {};

  const [width, height] = img?.match(/\d{2,}x\d{2,}/)?.[0]?.split('x').map(Number) || [2000, 1500];

  const dateRegex = /(\d{4})\/(\d{2})\/(\d{2})/;
  const match = img?.match(dateRegex);
  const datePublished = match ? `${match[1]}-${match[2]}-${match[3]}` : "";

  if (!img || img === "#" || img === "") {
    return <></>;
  }

  const dataImageObject = {
    "@context": "https://schema.org",
    "@type": "imageObject",
    "contentUrl": img,
    "height": width,
    "width": height,
    "datePublished": datePublished,
    "creator": {
      "@type": "Organization",
      "name": authorPhoto,
    },
    "name": titlePhoto,
    "description": epigraphe,
  };

  return (
    <Script
      strategy="beforeInteractive"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(dataImageObject) }}
    />
  );
};

export default LdjsonImageObject;
