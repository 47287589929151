import { isValidDateForObjectDate } from "helpers";
import Head from "next/head";

const LdjsonOpta = ({ dataOpta, dataNews }) => {

  let imgUrl =
    dataNews?.relatedVideos?.thumbnail ||
    dataNews?.related_image_url ||
    ''
  const description = dataNews?.summary || 'Campeonato de Fútbol'
  const parseDesc = description.replace(/<[^>]*>?/g, '')

  const [width, height] = imgUrl
    .match(/\d+x\d+/)?.[0]
    ?.split('x')
    .map(Number) || [320, 180]

  const teams = Object.values(dataOpta?.teams ?? {}) ?? []
  const dataTeams = teams.map(item => ({
    "@type": "SportsTeam",
    name: item?.name ?? "",
    sport: dataOpta?.sport ?? "",
    image: `https://www.clarin.com/img/collections/escudos/${item?._id}.png`,
    url: item?.name ? `https://www.clarin.com/tema/${item?.name?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(' ','-')}.html` : null, 
  }))
  const data = {
    "@context": "https://schema.org",
    "@type": "SportsEvent",
    name: dataOpta?.category?.name ? dataOpta.category.name : "Partido de Fútbol",
    description: parseDesc,
    eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',
    location: {
      '@type': 'VirtualLocation',
      name: 'Clarín',
      url: "https://www.clarin.com",
    },
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      price: '0.00',
      priceCurrency: 'ARS',
      validFrom:  isValidDateForObjectDate(dataOpta?.dateTime) ? new Date(dataOpta?.dateTime).toISOString() : "",
      url: dataNews.url,
    },
    organizer: {
      '@type': 'Organization',
      name: 'Clarín',
      url: 'https://www.clarin.com/',
    },
    image: {
      '@type': 'ImageObject',
      url: imgUrl,
      height: height,
      width: width,
    },
    startDate: isValidDateForObjectDate(dataOpta?.dateTime) ? new Date(dataOpta?.dateTime).toISOString() : "",
    endDate: isValidDateForObjectDate(dataOpta?.modificatedAt) ? new Date(dataOpta?.modificatedAt).toISOString() : "",
    performer: dataTeams
  };
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    </Head>
  );
};

export default LdjsonOpta;
