import Script from 'next/script';

const LdjsonVideoObject = ({ dataVideo }) => {

  const relatedVideos = dataVideo?.relatedVideos;
  const name = relatedVideos?.name ?? "";
  const thumbnail = relatedVideos?.thumbnail ?? "";
  const contentUrl = relatedVideos?.videoFiles?.mp4 ?? "";
  const embedUrl = relatedVideos?.entityId ?? "";
  const description = relatedVideos?.description?.replace(/<\/?[^>]+(>|$)/g, "") ?? "";
  const duration = relatedVideos?.duration;
  const epigraphe = relatedVideos?.epigraphe ?? "";
  
  const flavor720p = relatedVideos?.flavors?.find(flavor => flavor?._id === "720p");
  const thumbnailUrl = flavor720p?.thumbnails?.[0]?.url ?? flavor720p?.thumbnail ?? thumbnail;

  function convertirDuracionISO(duracion) {
    let [minutos, segundos] = duracion?.split(":").map(Number) ?? [];
    return `PT${minutos ? minutos + "M" : ""}${segundos ? segundos + "S" : ""}`;
  }

  const dataVideoObject = {
    "@context": "https://schema.org",
    "@type": "videoObject",
    name,
    description,
    thumbnailUrl,
    thumbnail: {
      "@type": "ImageObject",
      contentUrl: thumbnailUrl,
      caption: epigraphe,
      sourceOrganization: {
        "@type": "Organization",
        name: "Clarín",
      },
    },
    contentUrl,
    embedUrl: `https://cdn.jwplayer.com/players/${embedUrl}.html`,
    uploadDate: dataVideo?.publishedDate,
    dateModified: dataVideo?.lastModified,
    duration: convertirDuracionISO(duration),
    inLanguage: "es",
    headline: name,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@context": "https://schema.org",
      url: dataVideo.url,
      dateModified: dataVideo?.lastModified,
      inLanguage: "es",
      publisher: {
        "@type": "NewsMediaOrganization",
        url: "https://www.clarin.com/",
        logo: "https://www.clarin.com/img/logo_clarin_news.png",
        foundingDate: "1945-08-28",
        sameAs: [
          "https://www.facebook.com/clarincom/",
          "https://x.com/clarincom/",
          "https://www.linkedin.com/company/clarin/?originalSubdomain=ar",
          "https://www.instagram.com/clarincom/",
          "https://www.youtube.com/@clarincom",
        ],
      },
      name,
      headline: epigraphe,
      description,
      datePublished: dataVideo?.publishedDate,
    },
  };

  return (
    <Script
      strategy="beforeInteractive"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(dataVideoObject) }}
    />
  );
};

export default LdjsonVideoObject;